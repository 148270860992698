var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("info-abt-the-dead")))]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "exampleInputEmail1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.designation,
      expression: "form.designation"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "designation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeDesignation]
    }
  }, _vm._l(_vm.designations, function (designation) {
    return _c('option', {
      key: designation,
      domProps: {
        "value": designation
      }
    }, [_vm._v(" " + _vm._s(designation) + " ")]);
  }), 0)]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "full_name",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####'],
      "readonly": ""
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    attrs: {
      "for": "other_ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.other_ic_number,
      expression: "form.other_ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "other_ic_number",
      "required": ""
    },
    domProps: {
      "value": _vm.form.other_ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "other_ic_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "dob"
    }
  }, [_vm._v(_vm._s(_vm.$t("date-of-death"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.date_of_death,
      expression: "form.date_of_death"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date",
      "id": "dob",
      "required": ""
    },
    domProps: {
      "value": _vm.form.date_of_death
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "date_of_death", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.gender.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "gender"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.gender.$model,
      expression: "$v.form.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.gender")) + " --")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.gender.$error && !_vm.$v.form.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.religion.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "religion"
    }
  }, [_vm._v(_vm._s(_vm.$t("religion"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.religion.$model,
      expression: "$v.form.religion.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.religion, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeReligion]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.religion")) + " --")]), _vm._l(_vm.religions, function (religion) {
    return _c('option', {
      key: religion,
      domProps: {
        "value": religion
      }
    }, [_vm._v(" " + _vm._s(religion) + " ")]);
  })], 2), _vm.$v.form.religion.$error && !_vm.$v.form.religion.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("religion")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.race.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "race"
    }
  }, [_vm._v(_vm._s(_vm.$t("race"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.race.$model,
      expression: "$v.form.race.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "race"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.race, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeRace]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.race")) + " --")]), _vm._l(_vm.races, function (race) {
    return _c('option', {
      key: race,
      domProps: {
        "value": race
      }
    }, [_vm._v(" " + _vm._s(race) + " ")]);
  })], 2), _vm.$v.form.race.$error && !_vm.$v.form.race.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("race")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.marital_status.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "marital_status"
    }
  }, [_vm._v(_vm._s(_vm.$t("marital-status")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.marital_status.$model,
      expression: "$v.form.marital_status.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.marital_status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" -- " + _vm._s(_vm.$t("choose.marital-status")) + " -- ")]), _c('option', {
    attrs: {
      "value": "Bujang"
    }
  }, [_vm._v(_vm._s(_vm.$t("single")))]), _c('option', {
    attrs: {
      "value": "Berkahwin"
    }
  }, [_vm._v(_vm._s(_vm.$t("married")))]), _c('option', {
    attrs: {
      "value": "Janda/Duda"
    }
  }, [_vm._v(_vm._s(_vm.$t("widow-widower")))])]), _vm.$v.form.marital_status.$error && !_vm.$v.form.marital_status.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("marital-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.correspondence_address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "correspondence_address"
    }
  }, [_vm._v(_vm._s(_vm.$t("last-address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.correspondence_address.$model,
      expression: "$v.form.correspondence_address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "correspondence_address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.$v.form.correspondence_address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.correspondence_address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.correspondence_address.$error && !_vm.$v.form.correspondence_address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("last-address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "correspondence_postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.$v.form.correspondence_postcode.$model,
      expression: "$v.form.correspondence_postcode.$model",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "correspondence_postcode"
    },
    domProps: {
      "value": _vm.$v.form.correspondence_postcode.$model
    },
    on: {
      "blur": [function ($event) {
        return _vm.getPostcode(1);
      }, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.$set(_vm.$v.form.correspondence_postcode, "$model", $event.target.value.trim());
      }
    }
  }), _vm.$v.form.correspondence_postcode.$error && !_vm.$v.form.correspondence_postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "correspondence_city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.correspondence_city,
      expression: "form.correspondence_city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "correspondence_city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.correspondence_city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "correspondence_city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "correspondence_state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.correspondence_state,
      expression: "form.correspondence_state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "correspondence_state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.correspondence_state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "correspondence_state", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group position-relative col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "income_source"
    }
  }, [_vm._v(_vm._s(_vm.$t("source-income")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formHibah.income_source,
      expression: "formHibah.income_source"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formHibah, "income_source", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.dropdownSourceIncome($event);
      }]
    }
  }, _vm._l(_vm.income, function (incomeVal) {
    return _c('option', {
      key: incomeVal,
      domProps: {
        "value": incomeVal
      }
    }, [_vm._v(" " + _vm._s(incomeVal) + " ")]);
  }), 0)]), _vm.otherSourceIncome ? _c('div', {
    staticClass: "form-group position-relative col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "income_source"
    }
  }, [_vm._v(_vm._s(_vm.$t("othersource-income")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.income_source,
      expression: "form.income_source"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "income_source",
      "placeholder": "Sila nyatakan"
    },
    domProps: {
      "value": _vm.form.income_source
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "income_source", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "form-group position-relative col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "hibah_property_source"
    }
  }, [_vm._v(_vm._s(_vm.$t("trust-prop-resources")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formHibah.hibah_property_source,
      expression: "formHibah.hibah_property_source"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "hibah_property_source"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formHibah, "hibah_property_source", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changeincomeproperty($event);
      }]
    }
  }, _vm._l(_vm.income_property, function (incomeproperty) {
    return _c('option', {
      key: incomeproperty,
      domProps: {
        "value": incomeproperty
      }
    }, [_vm._v(" " + _vm._s(incomeproperty) + " ")]);
  }), 0)]), _vm.otherHibahPropSource ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_property_source"
    }
  }, [_vm._v(_vm._s(_vm.$t("other-hibah-prop-resources")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.hibah_property_source,
      expression: "form.hibah_property_source"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "hibah_property_source",
      "id": "hibah_property_source",
      "placeholder": "Sila nyatakan"
    },
    domProps: {
      "value": _vm.form.hibah_property_source
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "hibah_property_source", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formHibah.is_revocable,
      expression: "formHibah.is_revocable"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "check_revocable",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.formHibah.is_revocable) ? _vm._i(_vm.formHibah.is_revocable, "1") > -1 : _vm.formHibah.is_revocable
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.formHibah.is_revocable,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formHibah, "is_revocable", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formHibah, "is_revocable", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formHibah, "is_revocable", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "check_revocable"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.can-revoked")) + " ")])])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("trust.hibah-purpose")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formHibah.trust_purpose_type,
      expression: "formHibah.trust_purpose_type"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formHibah, "trust_purpose_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Institusi"
    }
  }, [_vm._v(_vm._s(_vm.$t("institution")))]), _c('option', {
    attrs: {
      "value": "Persendirian"
    }
  }, [_vm._v(_vm._s(_vm.$t("private2")))])])]), _vm.formHibah.trust_purpose_type == 'Institusi' ? _c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formHibah.trust_purpose,
      expression: "formHibah.trust_purpose"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "cols": "30",
      "rows": "10"
    },
    domProps: {
      "value": _vm.formHibah.trust_purpose
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formHibah, "trust_purpose", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.formHibah.trust_purpose_type == 'Persendirian' ? _c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formHibah.trust_purpose,
      expression: "formHibah.trust_purpose"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formHibah, "trust_purpose", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changeHibahPrivate($event);
      }]
    }
  }, _vm._l(_vm.hibah_private, function (hibahPrivate, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": hibahPrivate
      }
    }, [_vm._v(" " + _vm._s(hibahPrivate) + " ")]);
  }), 0)]) : _vm._e()]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group position-relative col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "is_has_will"
    }
  }, [_vm._v(_vm._s(_vm.$t("will-info")))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group position-relative col-md-6"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.is_has_will,
      expression: "form.is_has_will"
    }],
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": "0"
    },
    domProps: {
      "checked": _vm._q(_vm.form.is_has_will, "0")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "is_has_will", "0");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("no")) + " ")])])])]), _c('div', {
    staticClass: "form-group position-relative col-md-6"
  }, [_c('div', {
    staticClass: "radio-inline"
  }, [_c('div', [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.is_has_will,
      expression: "form.is_has_will"
    }],
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.form.is_has_will, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "is_has_will", "1");
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("yes")) + " ")])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('add'),
      "cancel-title": _vm.$t('cancel')
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "number"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text"
    }
  })])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }